import React from 'react';
import styled from 'styled-components';
import { Box } from './common/Box';
import { ButtonHover } from './common/ButtonHover';
import { H1 } from './common/Heading';
import calendar from '../images/icons/calendar.svg';
import cost from '../images/icons/cost.svg';
import place from '../images/icons/place.svg';
import online from '../images/icons/online.svg';
import headphones from '../images/icons/headphones.svg';
import {
  ArticleQuery_sanityArticle_events,
  ArticleQuery_sanityArticle_events_eventDates,
} from '../__generated__/graphql';
import { extractWithLocale } from '../utils/extractWithLocale';
import { useIsMobile } from '../hooks/useIsMobile';
import { useTranslation } from '../i18n/useTranslation';
import { Text } from './common/Text';

export interface ArticleEventProps {
  event: ArticleQuery_sanityArticle_events;
  kicker?: string;
  title: string | null;
  locale: 'sv' | 'en';
  isFirstItem: boolean;
}

export const ArticleEvent: React.FC<ArticleEventProps> = props => {
  const t = useTranslation();

  const isFirst = props.isFirstItem;

  const eventCost = props.event.eventCost && extractWithLocale(props.event.eventCost, props.locale);
  const eventPlace =
    props.event.eventPlace && extractWithLocale(props.event.eventPlace, props.locale);
  const eventJoinButton =
    props.event.eventJoinButton && extractWithLocale(props.event.eventJoinButton, props.locale);
  const eventDescription = props.event.eventDescription && extractWithLocale(props.event.eventDescription, props.locale);

  const isMobile = useIsMobile();

  let eventIcon = '';
  switch (props.event.eventIcon) {
    case 'place':
      eventIcon = place;
      break;
    case 'online':
      eventIcon = online;
      break;
    case 'headphones':
      eventIcon = headphones;
      break;
  }

  const onEventJoinClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!props.event.eventUrl) {
      return;
    }

    window.dataLayer.push({ event: 'interactionsTalksSignUp', pagePath: window.location.href });
    window.location.href = props.event.eventUrl;
  };

  return (
    <Box style={{ top: '6rem', width: '100%' }} mb={3}>
      {!isMobile && isFirst && (
        <>
          <hr
            style={{ borderTop: '1px solid black', borderBottom: 0, paddingTop: 0, margin: 0 }}
          />

          {props.kicker && (
            <Text
              fontSize={[3, 26]}
              mt={[3]}
              ml={'2px'}
              fontFamily="Handsome"
              style={{ display: 'inline-flex' }}
            >
              {props.kicker}
            </Text>
          )}

          <H1 fontSize={[5]} mb={3} mt={props.kicker ? 0 : 3}>
            {props.title}
          </H1>
        </>
      )}
      <hr style={{ borderTop: '1px solid black', borderBottom: 0 }} />

      <Box style={{ fontSize: 15, fontWeight: 500 }}>
        {props.event.eventDates && props.event.eventDates?.length > 0 && (
          <Box mt={3} mb={3}>
            <StyledArticleEventIcon icon={calendar} />
            <Box ml={4}>
              {props.event.eventDates?.map((date, index) =>
                renderDate(date, props.locale, index)
              )}
            </Box>
          </Box>
        )}

        <Box mb={3}>
          {eventCost && (
            <>
              <StyledArticleEventIcon icon={cost} />
              <Box ml={4}>{eventCost}</Box>
            </>
          )}
        </Box>

        <Box mb={eventDescription ? 3 : 4}>
          {eventPlace && (
            <>
              <StyledArticleEventIcon icon={eventIcon} />
              <Box ml={4}>
                {eventPlace}
                {props.event.eventIcon === 'place' && !!props.event.eventPlaceUrl && (
                  <a
                    href={props.event.eventPlaceUrl}
                    style={{ marginLeft: 10, fontWeight: 300 }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('show')}
                  </a>
                )}
              </Box>
            </>
          )}
        </Box>

        {eventDescription &&
          <Box mb={3} ml={4}>
            {eventDescription}
          </Box>
        }

        <ButtonHover
          href={props.event.eventUrl || ''}
          style={{ width: '100%' }}
          onClick={onEventJoinClick}
        >
          {eventJoinButton}
        </ButtonHover>
      </Box>
    </Box>
  );
};

export const hasEventPassed = (eventDates: ArticleQuery_sanityArticle_events_eventDates[]) => {
  if (eventDates.length && !eventDates[0].endDate && !eventDates[0].startDate) {
    return false;
  }

  const maxStartDate = new Date(
    Math.max.apply(null, eventDates.map(dates => new Date(dates.startDate)) as any[])
  );
  const maxEndDate = new Date(
    Math.max.apply(null, eventDates.map(dates => new Date(dates.endDate)) as any[])
  );

  const maxDate = maxStartDate > maxEndDate ? maxStartDate : maxEndDate;
  const now = new Date();

  return maxDate < now;
};

export const hasEventsPassed = (events: (ArticleQuery_sanityArticle_events | null)[]) => {
  if (!events) {
    return true;
  }

  let passedEvents = 0;

  events.forEach(event => {
    const hasPassed = hasEventPassed(event?.eventDates as ArticleQuery_sanityArticle_events_eventDates[]);

    passedEvents += hasPassed ? 1 : 0;
  });

  return passedEvents === events.length;
}

const renderDate = (
  date: ArticleQuery_sanityArticle_events_eventDates | null,
  locale: 'sv' | 'en',
  index: number
) => {
  const startDate = date?.startDate && new Date(date?.startDate);
  const endDate = date?.endDate && new Date(date?.endDate);
  const comment = extractWithLocale(date?.comment, locale);

  const mt = index === 0 ? 0 : 2;
  const fontWeight = startDate ? 400 : 500;

  return (
    <Box>
      {comment && (
        <Text fontWeight={fontWeight} mt={mt}>
          {comment}
        </Text>
      )}

      {startDate &&
        Intl.DateTimeFormat(locale, {
          day: '2-digit',
          month: 'long',
          hour: '2-digit',
          minute: '2-digit',
        }).format(startDate)}

      {endDate && (
        <>
          -
          {Intl.DateTimeFormat(locale, {
            hour: '2-digit',
            minute: '2-digit',
          }).format(endDate)}
        </>
      )}
    </Box>
  );
};

export const ArticleEventBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;
  position: sticky;
  top: 6rem;

  @media screen and (max-width: 640px) {
    padding: 0;
    margin-bottom: 1rem;
  }
`;

const StyledArticleEventIcon = styled(Box) <{ icon: string }>`
  &::before {
    content: url(${props => props.icon});
    position: absolute;
    width: 1.4em;
  }
`;
