import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { BlockContentRenderer } from '../components/common/BlockContentRenderer';
import { Box } from '../components/common/Box';
import { Grid } from '../components/common/Grid';
import { H1 } from '../components/common/Heading';
import { InnerGrid } from '../components/common/InnerGrid';
import { Text } from '../components/common/Text';
import { Layout } from '../components/Layout';
import { SectionRenderer } from '../components/sections/SectionRenderer';
import { Seo } from '../components/Seo';
import { bodyPadding, grid } from '../theme/theme';
import { extractWithLocale } from '../utils/extractWithLocale';
import { getSlugsFromPage } from '../utils/getSlugsFromPage';
import { isNotNull } from '../utils/isNotNull';
import { PageProps } from '../utils/PageProps';
import { combineFullSections } from '../utils/rawSectionHelpers';
import { ArticleQuery } from '../__generated__/graphql';
import { isIE } from 'react-device-detect';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { ArticleEvent, ArticleEventBox, hasEventsPassed } from '../components/ArticleEvent';
import { useIsMobile } from '../hooks/useIsMobile';
import { EmbeddedVideoSection } from '../components/sections/EmbeddedVideoSection';
import { getFixedGifSrc } from '../utils/getFixedGifSrc';
import { TalksProvider } from '../components/TalksProvider';
import { useThemeContext } from '../components/ThemeChanger';
import Password from '../components/Password';

const ArticlePage: React.FC<PageProps<ArticleQuery>> = ({ data, pageContext, location }) => {
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const themeCtx = useThemeContext();
  useEffect(() => {
    setNotSupportedBrowser(isIE);
    themeCtx.setTheme('light');
  }, []);
  const isMobile = useIsMobile();

  const article = data.sanityArticle!;

  if (!article) return null;

  const title = extractWithLocale(article.name, pageContext.locale);
  const preamble = extractWithLocale(article.preamble, pageContext.locale);
  const heading = extractWithLocale(article.heading, pageContext.locale);
  const kicker = article.category?.title || 'Stuff';

  const slugs = getSlugsFromPage(article, 'stuff');

  const seoTitle = article.seo && extractWithLocale(article.seo.title, pageContext.locale);
  const seoDescription =
    article.seo && extractWithLocale(article.seo.description, pageContext.locale);
  const seoImage =
    article.seo && article.seo.seoImage?.asset?.gatsbyImageData?.images?.fallback?.src;

  const sections = article.sections?.filter(isNotNull) ?? [];
  const rawSections = article._rawSections?.filter(isNotNull) ?? [];
  const fullSections = combineFullSections(sections, rawSections);

  const events = article.events;
  const eventsPassed = events && hasEventsPassed(events);
  const showEvents = events && events.length > 0 && !eventsPassed;

  const passwordHeading = extractWithLocale(article.passwordProtect?.heading, pageContext.locale);
  const passwordContent = extractWithLocale(article.passwordProtect?.content, pageContext.locale);

  const contentWidth = !showEvents || isMobile ? '1 / -1' : '1 / 5';
  const articleEventWidth = isMobile ? '1 / -1' : '5 / 7';

  let boxGridColumn = ['1 / -1', '2 / -2'];

  if (fullSections.length > 0) {
    boxGridColumn = ['1 / -1'];
  }

  const fluidImage = getFixedGifSrc(article?.image?.asset?.gatsbyImageData);

  const renderHero = () => {
    if (article.embeddedVideo && article.embeddedVideo.videoUrl) {
      return (
        <Box gridColumn="1 / -1" mb={4}>
          <EmbeddedVideoSection section={article.embeddedVideo} />
        </Box>
      );
    }

    if (fluidImage) {
      return (
        <Box gridColumn="1 / -1" mb={4}>
          <GatsbyImage image={{ ...(fluidImage as any), aspectRatio: 16 / 9 }} />
        </Box>
      );
    }
  };

  return notSupportedBrowser ? (
    <Layout
      locale={pageContext.locale}
      alternativeLinks={slugs}
      overlayHeaderOverContent={true}
      location={location}
      showFooter={false}
    >
      <Seo
        title={seoTitle || title || ''}
        description={seoDescription || preamble || ''}
        type="article"
        location={location}
      />
      <Grid gridTemplateColumns={grid} data-component="wut">
        <BrowserNotSupported locale={pageContext.locale} />
      </Grid>
    </Layout>
  ) : (
    <TalksProvider show={article.showEsattoTalksBubble}>
      <Layout locale={pageContext.locale} location={location} alternativeLinks={slugs}>
        <Seo
          title={seoTitle || title || ''}
          description={seoDescription || preamble || ''}
          image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
          type="article"
          location={location}
        />
        <Password
          pageId={pageContext.id}
          password={article.passwordProtect?.password}
          heading={passwordHeading}
          content={passwordContent}
        >
          <Grid pb={6} gridTemplateColumns={grid}>
            <InnerGrid px={bodyPadding} columns={6}>
              <Box gridColumn="1 / -1">
                <Text fontSize={[3, 26]} fontFamily="Handsome" style={{ height: '1em' }}>
                  {article.category?.title || 'Stuff'}
                </Text>
                <H1 fontSize={[4, 5, 6]} mt={0}>
                  {heading}
                </H1>
              </Box>
              <Box gridColumn={contentWidth}>
                {renderHero()}
                <Box mb={5} gridColumn={boxGridColumn}>
                  <Box maxWidth={800} mb={4}>
                    <Text fontSize={[3, 4]} fontWeight={300} lineHeight={1.4}>
                      {preamble}
                    </Text>
                  </Box>
                  <Box maxWidth={800} mb={4}>
                    <BlockContentRenderer body={article._rawBody || []} />
                  </Box>
                </Box>
              </Box>
              {events && events.length > 0 && (
                <Box gridColumn={articleEventWidth}>
                  <ArticleEventBox>
                    {events.map(
                      (event, index) =>
                        event && (
                          <ArticleEvent
                            event={event}
                            title={title}
                            kicker={kicker}
                            locale={pageContext.locale}
                            isFirstItem={index === 0}
                          />
                        )
                    )}
                  </ArticleEventBox>
                </Box>
              )}
            </InnerGrid>
            <SectionRenderer sections={fullSections} />
          </Grid>
        </Password>
      </Layout>
    </TalksProvider>
  );
};

export const query = graphql`
  query ArticleQuery($id: String!, $isEnglish: Boolean!, $isSwedish: Boolean!) {
    sanityArticle(_id: { eq: $id }) {
      name {
        en @include(if: $isEnglish)
        sv @include(if: $isSwedish)
      }
      slug {
        en {
          ...Slug
        }
        sv {
          ...Slug
        }
      }
      passwordProtect {
        heading {
          en

          sv
        }
        content {
          en

          sv
        }

        password
      }
      category {
        title
      }
      heading {
        en
        sv
      }
      preamble {
        en @include(if: $isEnglish)
        sv @include(if: $isSwedish)
      }
      _rawBody
      embeddedVideo {
        _key
        __typename
        videoUrl
        imagePreview {
          asset {
            gatsbyImageData
          }
        }
        videoLength
        isFullWidth
      }
      showEsattoTalksBubble
      image {
        asset {
          gatsbyImageData
        }
      }
      imageAltText {
        sv
        en
      }
      seo {
        title {
          sv

          en
        }
        description {
          sv

          en
        }
        seoImage: image {
          asset {
            gatsbyImageData(layout: FIXED, width: 1200, height: 630)
          }
        }
        clearTemplate
        follow
        index
      }
      events {
        ... on SanityEvent {
          showEvent
          eventUrl
          eventDates {
            startDate
            endDate
            comment {
              sv
              en
            }
          }
          eventIcon
          eventPlaceUrl
          eventCost {
            sv
            en
          }
          eventPlace {
            sv
            en
          }
          eventJoinButton {
            sv
            en
          }
          eventDescription {
            sv
            en
          }
        }
      }
      _rawSections
      sections {
        ...Sections
      }
    }
  }

  fragment Slug on SanitySlug {
    current
  }
`;

export default ArticlePage;
