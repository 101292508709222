import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const mockedStorage: Storage = {
  clear() {},
  getItem: () => null,
  key: () => null,
  length: 0,
  removeItem: () => {},
  setItem: () => {},
};

const storage: Storage = typeof window !== 'undefined' ? window.sessionStorage : mockedStorage;

export const useSessionState = <T>(
  cacheKey: string,
  initialState: T | (() => T)
): [T, Dispatch<SetStateAction<T>>] => {
  const key = getCacheKey(cacheKey);

  const [state, setState] = useState<T>(() => {
    const fromStorage = storage.getItem(key);

    if (fromStorage == null) {
      return initialState instanceof Function ? initialState() : initialState;
    }

    return JSON.parse(fromStorage);
  });

  useEffect(() => {
    storage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

const getCacheKey = (key: string) => `esatto-se-${key}`;
