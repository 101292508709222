import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSessionState } from '../hooks/useSessionState';
import { useTranslation } from '../i18n/useTranslation';
import { color } from '../theme/color';
import { grid } from '../theme/theme';
import { Box } from './common/Box';
import { ButtonMagnetic } from './common/ButtonMagnetic';
import { Grid } from './common/Grid';
import { H2 } from './common/Heading';
import { InnerGrid } from './common/InnerGrid';
import Input from './common/Input';
import { ThemeContextType, useCurrentThemeName } from './ThemeChanger';
import md5 from 'md5';

export type PasswordType = {
  children: any;
  pageId: string;
  password?: string | null;
  heading?: string | null;
  content?: string | null;
}

const Password: React.FC<PasswordType> = ({
  children,
  pageId,
  password,
  heading,
  content,
}) => {
  const storageId = `userPasswords[${pageId}]`;
  const [userPassword, setUserPassword] = useSessionState(storageId, '');
  const [showContent, setShowContent] = useState(false);

  const t = useTranslation();
  const themeName = useCurrentThemeName();
  const currentColor = themeName === 'dark' ? color.white : color.black;

  const setContentVisible = () => {
    setShowContent(!password || userPassword === md5(password));
  }

  const onSubmit = () => {
    setContentVisible();
  }

  useEffect(() => {
    setContentVisible();
  }, []);

  if (showContent) {
    return children;
  }

  return <Grid gridTemplateColumns={grid} data-component="wut" p={[3, 6]} py={6} pt={[5, 6]}>
    <InnerGrid columns={1}>
      <StyledBox>
        <H2 mb={0}>{heading}</H2>
        <Box color={currentColor} style={{ fontWeight: 300 }}>{content}</Box>

        <StyledInput
          type='password'
          onChange={event => setUserPassword(md5(event.target.value))}
          onKeyPress={event => event.key === 'Enter' && onSubmit()}
          themeName={themeName}
        />

        <Box style={{ marginRight: 0, marginLeft: 'auto' }} mt={4}>
          <ButtonMagnetic onClick={onSubmit}>{t('login')}</ButtonMagnetic>
        </Box>
      </StyledBox>
    </InnerGrid>
  </Grid>
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledInput = styled(Input) <{ themeName: ThemeContextType['current'] }>`
  border: 1px solid #111;
  padding: 1rem;
  padding-left: 2rem;
  margin-top: 1rem;
  width: auto;
  color:#111;
  background-color: ${props => props.themeName === 'dark' ? '#eee' : 'transparent'};
  font-size: 16px;

  &::focus, &::focus-visible {
    outline: 0;
  }
`;

export default Password;